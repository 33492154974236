export default {
  QQ_CLIENT_ID: "1175678372",
  QQ_REDIRECT_URL: "http://116.63.178.241:8080/callback/qq",
  GITEE_CLIENT_ID: "95a2e8e1291092d8944b8f4b53a54a3706f8f95610b40fa19cc4a0c2cb82f80f",
  GITEE_REDIRECT_URL: "https://zhangshanbing.fun/callback/gitee",
  //GITEE_CLIENT_ID: "7546925e8f771ef46a22d6b02a130137d612a7adb65b432c78464102f68dd37f",
  //GITEE_REDIRECT_URL: "http://116.63.178.241:8080/callback/gitee",
  WEIBO_CLIENT_ID: "2285022919",
  WEIBO_REDIRECT_URL: "http://116.63.178.241:8080/callback/weibo",
};
